
import { USER_COUNTRY } from '../actions/types'

const initialValues = {
  country: '',
}

const userCountry = (state = initialValues, action) => {
  switch (action.type) {
    case USER_COUNTRY:
      return {
        ...state,
        country: action.payload,
      }
    default:
      return state
  }
}

export default userCountry