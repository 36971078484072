//authentication actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL'
export const LOGOUT = 'LOGOUT';
// Employers's actions
export const POST_PROJECT_SUCCESS = 'POST_PROJECT_SUCCESS';
export const POST_PROJECT_FAIL = 'POST_PROJECT_FAIL'
export const PROJECT_LOAD_SUCCESS = 'PROJECT_POST_SUCCESS'
export const PROJECT_LOAD_FAIL = 'PROJECT_LOAD_FAIL'
export const PROJECTS_IN_DISPUTE = 'PROJECTS_IN_DISPUTE'
export const COMPLETED_PROJECTS = 'COMPLETED_PROJECTS'
export const PROJECTS_IN_REVISION = 'PROJECTS_IN_REVISION'
export const EMPLOYER_UNASSIGNED_PROJECTS = 'EMPLOYER_ANASSIGNED_PROJECTS'
// Writer's Actions
export const POST_BID_SUCCESS = 'POST_BID_SUCCESS'
export const POST_BID_FAIL = 'POST_BID_FAIL'
export const PROJECT_DISPLAY_SUCCESS = 'PROJECT_DISPLAY_SUCCESS'
export const PROJECT_DISPLAY_FAIL = 'PROJECT_DISPLAY_FAIL'
// get user
export const CURRENT_USER_LOADED_SUCCESS = 'CURRENT_USER_LOADED_SUCCESS'
export const CURRENT_USER_LOADED_FAIL = 'CURRENT_USER_LOADED_FAIL'
//
export const ADD_MESSAGE_ROOM = 'ADD_MESSAGE_ROOM'
export const REMOVE_MESSAGE_ROOM = 'REMOVE_MESSSAGE_ROOM'
export const PROJECT_ESCROW_SUCCESS = 'PROJECT_ESCROW_SUCCESS'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const WINDOWSIZE = 'WINDOWSIZE'
export const USER_COUNTRY = 'USER_COUNTRY'
export const NO_USER_COUNTRY = 'NO_USER_COUNTRY'
