import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './store';
import { Provider } from 'react-redux';
import App from './App';

//Check if service workers are supported by the browser

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        localStorage.setItem('serviceWorker', true);
        console.log(
          'Service Worker registered with scope:',
          registration.scope
        );
      })
      .catch((err) => {
        console.log('Service Worker registration failed:', err);
        localStorage.setItem('serviceWorker', false);
      });
  });
}

// Check if the navigator.setAppBadge API is supported
const isSetAppBadgeSupported = navigator.setAppBadge !== undefined;

// Reset the badge count when the PWA is opened
function resetBadgeCount() {
  if (isSetAppBadgeSupported) {
    // Reset the badge count to 0
    navigator.clearAppBadge();
  }

  // Also update the local storage variable
}

// Add an event listener for the PWA open event
document.addEventListener('DOMContentLoaded', () => {
  // Call the resetBadgeCount function when the PWA is opened
  resetBadgeCount();
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
