import * as actions from '../actions/types';

const initialState = {
  access: localStorage.getItem('access'),
  refresh: localStorage.getItem('refresh'),
  isAuthenticated: null,
  user: null,
};

function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case actions.LOGIN_SUCCESS:
      localStorage.setItem('access', payload.access);
      localStorage.setItem('refresh', payload.refresh);
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access,
        refresh: payload.refresh,
      };
    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    case actions.USER_LOADED_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case actions.AUTHENTICATED_FAIL:
      return {
        ...state,
        isAuthenticated: false,
      };
    case actions.USER_LOADED_FAIL:
      return {
        ...state,
        user: null,
      };
    case actions.LOGIN_FAIL:
    case actions.SIGNUP_FAIL:
    case actions.LOGOUT:
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
      };
    case actions.PASSWORD_RESET_SUCCESS:
    case actions.PASSWORD_RESET_FAIL:
    case actions.PASSWORD_RESET_CONFIRM_SUCCESS:
    case actions.PASSWORD_RESET_CONFIRM_FAIL:
    case actions.ACTIVATION_SUCCESS:
    case actions.ACTIVATION_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}

const this_user = {
  user: null,
};

function current_user(state = this_user, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.CURRENT_USER_LOADED_SUCCESS:
      return { ...state, user: payload };
    case actions.CURRENT_USER_LOADED_FAIL:
      return state;
    default:
      return state;
  }
}

export { auth, current_user };
