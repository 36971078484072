import * as actions from '../actions/types';

const initialState = {
  assigned: null,
  bidhigh: null,
  bids_on_project: [],
  deadline: null,
  description: null,
  duration: null,
  employer: {},
  formatstyle: null,
  id: null,
  level: null,
  pages: null,
  project_files: [],
  subject: null,
  timestamp: null,
  title: null,
  writer: null,
};

const unassigned_projects = [];
const projectsinprogress = [];
const projectsinrevision = [];
const completedprojects = [];

function project_post(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.POST_PROJECT_SUCCESS:
      return {
        ...state,
        assigned: payload.assigned,
        bids_on_project: payload.bids_on_project,
        deadline: payload.deadline,
        description: payload.description,
        duration: payload.duration,
        employer: payload.employer,
        formatstyle: payload.formatstyle,
        id: payload.id,
        level: payload.level,
        pages: payload.pages,
        project_files: payload.project_files,
        subject: payload.subject,
        timestamp: payload.timestamp,
        title: payload.title,
        writer: payload.writer,
      };
    case actions.POST_PROJECT_FAIL:
      return state;
    default:
      return state;
  }
}

export { project_post };
