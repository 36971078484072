import {configureStore} from '@reduxjs/toolkit'
import {auth, current_user} from './reducers/auth';
import {project_post } from './reducers/employer';
import { post_bids } from './reducers/writer'
import userCountry from './reducers/country';
import notification from './reducers/escrow';
import windowSize from './reducers/windowsize';

const store = configureStore(
    {reducer:{
        auth,
        project_post,
        post_bids,
        current_user,
        notification,
        windowSize,
        userCountry
       }
    }
);

export default store;
