import * as actions from '../actions/types'

const initialState = {}

const projectsState = []

export const post_bids = (state= initialState, action)=>{
    const {type, payload} = action

    switch(type){
        case actions.POST_BID_SUCCESS:
            return {...state, ...payload}
        case actions.POST_BID_FAIL:
            return {...state}
        default:
            return state
    }
}
